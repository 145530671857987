function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import Enumerator from './enumerator';
import { PENDING, FULFILLED, fulfill } from './-internal';

var PromiseHash = function (_Enumerator) {
  _inherits(PromiseHash, _Enumerator);

  function PromiseHash(Constructor, object) {
    var abortOnReject = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var label = arguments[3];
    return _possibleConstructorReturn(this, _Enumerator.call(this, Constructor, object, abortOnReject, label));
  }

  PromiseHash.prototype._init = function _init(Constructor, object) {
    this._result = {};
    this._enumerate(object);
  };

  PromiseHash.prototype._enumerate = function _enumerate(input) {
    var keys = Object.keys(input);

    var length = keys.length;
    var promise = this.promise;
    this._remaining = length;

    var key = void 0,
        val = void 0;
    for (var i = 0; promise._state === PENDING && i < length; i++) {
      key = keys[i];
      val = input[key];
      this._eachEntry(val, key, true);
    }

    this._checkFullfillment();
  };

  return PromiseHash;
}(Enumerator);

export default PromiseHash;