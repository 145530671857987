function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import Promise from './promise';
import { default as Enumerator, setSettledResult } from './enumerator';
import PromiseHash from './promise-hash';

var HashSettled = function (_PromiseHash) {
  _inherits(HashSettled, _PromiseHash);

  function HashSettled(Constructor, object, label) {
    return _possibleConstructorReturn(this, _PromiseHash.call(this, Constructor, object, false, label));
  }

  return HashSettled;
}(PromiseHash);

HashSettled.prototype._setResultAt = setSettledResult;

/**
  `hashSettled` is similar to `allSettled`, but takes an object
  instead of an array for its `promises` argument.

  Unlike `all` or `hash`, which implement a fail-fast method,
  but like `allSettled`, `hashSettled` waits until all the
  constituent promises have returned and then shows you all the results
  with their states and values/reasons. This is useful if you want to
  handle multiple promises' failure states together as a set.

  Returns a promise that is fulfilled when all the given promises have been
  settled, or rejected if the passed parameters are invalid.

  The returned promise is fulfilled with a hash that has the same key names as
  the `promises` object argument. If any of the values in the object are not
  promises, they will be copied over to the fulfilled object and marked with state
  'fulfilled'.

  Example:

  ```javascript
  import { hashSettled, resolve } from 'rsvp';

  let promises = {
    myPromise: resolve(1),
    yourPromise: resolve(2),
    theirPromise: resolve(3),
    notAPromise: 4
  };

  hashSettled(promises).then(function(hash){
    // hash here is an object that looks like:
    // {
    //   myPromise: { state: 'fulfilled', value: 1 },
    //   yourPromise: { state: 'fulfilled', value: 2 },
    //   theirPromise: { state: 'fulfilled', value: 3 },
    //   notAPromise: { state: 'fulfilled', value: 4 }
    // }
  });
  ```

  If any of the `promises` given to `hash` are rejected, the state will
  be set to 'rejected' and the reason for rejection provided.

  Example:

  ```javascript
  import { hashSettled, reject, resolve } from 'rsvp';

  let promises = {
    myPromise: resolve(1),
    rejectedPromise: reject(new Error('rejection')),
    anotherRejectedPromise: reject(new Error('more rejection')),
  };

  hashSettled(promises).then(function(hash){
    // hash here is an object that looks like:
    // {
    //   myPromise:              { state: 'fulfilled', value: 1 },
    //   rejectedPromise:        { state: 'rejected', reason: Error },
    //   anotherRejectedPromise: { state: 'rejected', reason: Error },
    // }
    // Note that for rejectedPromise, reason.message == 'rejection',
    // and for anotherRejectedPromise, reason.message == 'more rejection'.
  });
  ```

  An important note: `hashSettled` is intended for plain JavaScript objects that
  are just a set of keys and values. `hashSettled` will NOT preserve prototype
  chains.

  Example:

  ```javascript
  import Promise, { hashSettled, resolve } from 'rsvp';

  function MyConstructor(){
    this.example = resolve('Example');
  }

  MyConstructor.prototype = {
    protoProperty: Promise.resolve('Proto Property')
  };

  let myObject = new MyConstructor();

  hashSettled(myObject).then(function(hash){
    // protoProperty will not be present, instead you will just have an
    // object that looks like:
    // {
    //   example: { state: 'fulfilled', value: 'Example' }
    // }
    //
    // hash.hasOwnProperty('protoProperty'); // false
    // 'undefined' === typeof hash.protoProperty
  });
  ```

  @method hashSettled
  @public
  @for rsvp
  @param {Object} object
  @param {String} [label] optional string that describes the promise.
  Useful for tooling.
  @return {Promise} promise that is fulfilled when when all properties of `promises`
  have been settled.
  @static
*/

export default function hashSettled(object, label) {
  return Promise.resolve(object, label).then(function (object) {
    if (object === null || typeof object !== 'object') {
      throw new TypeError("hashSettled must be called with an object");
    }

    return new HashSettled(Promise, object, false, label).promise;
  });
}